import apiCaller from "@/apiCalls/apiCaller";

export const imageUploader = {

    handleFilelUpload: async (images) => {
        const data = new FormData();

        for (let index = 0; index < images.length; index++) {
            data.append('files[]', images[index])
        }

        for (const entry of data.entries()) {
            console.log(entry[0] + ': ' + entry[1]);
        }

        const res = await apiCaller.post('image', data);
        return res;
    }
}
